import * as React from "react";
import AccountsMultiSelectPicker from "./AccountsMultiSelectPicker";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import WidgetTracker from "../common/components/WidgetTracker";
import { AccountWidget } from "../common/typings/accounts.typings";
import { SGBSSize } from "@sgbs-ui/core";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import { WithSGB4 } from "../common/components/WithSGB4";
import { AccountDisplayOption, CommonPickerProps, EmitOnChangeMode } from "../common/typings";
import { ACCOUNT_PLACEHOLDER, EMIT_MODE, MAX_RESULT_COUNT, SIZE } from "../common/constants";
import { SgDashboardProps, SgDashboardWidgetProps } from "../common/sgDashboard";
import {
  getInitialMultiSelection,
  notifyMultiChangeToSgDashboard,
  useEmitOnMount,
} from "../common/hooks/useUserSelection";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";

const TAG = "ic-account-multi-picker";

export interface Props extends CommonPickerProps<{ accounts: AccountWidget[] }, string>, SgDashboardProps {
  selectedIds?: string[];
  readonlyIds?: string[];
  levels?: string[];
  withFunds?: string;
  displayOption?: AccountDisplayOption;
  legalOnly?: string;
  suggestionIds?: string[];
  accountsIds?: string[];
  useHistory?: string;
  onClearSuggestions?: (items: AccountWidget[]) => void;
  onRemoveSuggestion?: (item: AccountWidget) => void;
}

export const AccountMultiPickerWidget: React.FC<Props> = ({
  id,
  selectedIds,
  readonlyIds,
  levels,
  inError,
  errorMessage,
  withFunds,
  onChange,
  onReady,
  onClearSuggestions,
  onRemoveSuggestion,
  emitMode = EMIT_MODE,
  maxResultCount = MAX_RESULT_COUNT,
  placeholder = ACCOUNT_PLACEHOLDER,
  size = SIZE,
  disabled,
  outline,
  userSelection,
  displayOption,
  setUserSelection,
  emitState,
  theme,
  legalOnly,
  suggestionIds,
  accountsIds,
  useHistory,
}) => {
  const handleOnSelectionChange = (accounts: AccountWidget[] | undefined = []): void => {
    notifyMultiChangeToSgDashboard({ userSelection, setUserSelection, emitState }, accounts);
    onChange?.({ accounts });
  };
  useEmitOnMount(userSelection, emitMode, () => onChange?.({ accounts: [] }));
  const initSelection = getInitialMultiSelection(userSelection, selectedIds);
  const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

  return (
    <WithSGB4 theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AccountsMultiSelectPicker
          id={id}
          maxResultCount={maxResultCount}
          placeholder={placeholder}
          selectedIds={initSelection}
          readonlyIds={readonlyIds}
          levels={levels}
          emitMode={emitMode}
          withFunds={convertToBoolOrUndefined(withFunds)}
          inError={convertToBoolOrUndefined(inError)}
          outline={convertToBoolOrUndefined(outline)}
          disabled={convertToBoolOrUndefined(disabled)}
          useHistory={convertToBoolOrUndefined(useHistory)}
          errorMessage={errorMessage}
          onChange={handleOnSelectionChange}
          onReady={onReady}
          size={size}
          displayOption={displayOption}
          legalOnly={convertToBoolOrUndefined(legalOnly)}
          suggestionIds={suggestionIds}
          onClearSuggestions={onClearSuggestions}
          onRemoveSuggestion={onRemoveSuggestion}
          accountsIds={accountsIds}
        />
        <WidgetTracker widgetName={TAG} />
      </QueryClientProvider>
    </WithSGB4>
  );
};

widgetize(
  TAG,
  AccountMultiPickerWidget,
  {
    id: WidgetPropsMapping.asString(),
    maxResultCount: WidgetPropsMapping.asNumber(),
    placeholder: WidgetPropsMapping.asString(),
    disabled: WidgetPropsMapping.asString(),
    outline: WidgetPropsMapping.asString(),
    size: WidgetPropsMapping.asString() as PropMapping<Props, SGBSSize>,
    inError: WidgetPropsMapping.asString(),
    errorMessage: WidgetPropsMapping.asString(),
    selectedIds: WidgetPropsMapping.asObject(),
    readonlyIds: WidgetPropsMapping.asObject(),
    levels: WidgetPropsMapping.asObject(),
    displayOption: WidgetPropsMapping.asString() as PropMapping<Props, AccountDisplayOption>,
    withFunds: WidgetPropsMapping.asString(),
    useHistory: WidgetPropsMapping.asString(),
    emitMode: WidgetPropsMapping.asString() as PropMapping<Props, EmitOnChangeMode>,
    onChange: WidgetPropsMapping.asEventEmitter("changed"),
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
    legalOnly: WidgetPropsMapping.asString(),
    suggestionIds: WidgetPropsMapping.asObject(),
    accountsIds: WidgetPropsMapping.asObject(),
    onClearSuggestions: WidgetPropsMapping.asEventEmitter("clear-suggestions"),
    onRemoveSuggestion: WidgetPropsMapping.asEventEmitter("remove-suggestion"),
    ...SgDashboardWidgetProps,
  },
  {
    neverInjectGlobalCss: true,
    includePolyfills: true,
  }
);
